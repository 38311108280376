<template>
    <div class="page" id="reorganize">
        <el-form class="query-form" size="small"
                 @keyup.enter.native="getDataList(1,1)"
                 ref="inputForm" :inline="true" :model="inputForm" label-width="80px">
            <el-form-item label="展览名称" prop="exhibitionName">
                <el-input v-model.trim="inputForm.exhibitionName"
                          placeholder="请输入展览名称(限50字)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item label="展览类型">
                <el-select v-model="inputForm.exhibitionType" clearable placeholder="请选择展览类型"
                           style="width: 100%;">
                    <el-option
                            v-for="item in this.$dictUtils.getDictList('exhibition_type')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="藏品名称" prop="collectionName">
                <el-input v-model.trim="inputForm.collectionName"
                          placeholder="请输入藏品名称(限50字)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item label="展览时间" prop="exhibitionTime">
                <el-date-picker
                        style="width: 100%"
                        v-model="inputForm.exhibitionTime"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                    <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询
                    </el-button>
                    <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
            </el-form-item>
            <el-form-item class="f_r">
                <el-button type="primary" icon="el-icon-circle-check" size="small" v-show="hasPermissionButton('collection:business:Review:batchAdopt')"
                           @click="through(1,3)">
                    批量通过
                </el-button>
                <el-button type="danger" icon="el-icon-delete" size="small" v-show="hasPermissionButton('collection:business:Review:batchReject')"
                           @click="through(1,2)">
                    批量驳回
                </el-button>
            </el-form-item>
        </el-form>

        <div class="bg-white">
            <div class="text_right">
                <el-button size="small" v-show="hasPermissionButton('collection:business:Review:batchExpor')"
                           @click="exportData(1)">
                    <i class="icon-piliangdaochu iconfont buIcon"/>
                    批量导出
                </el-button>
            </div>
            <el-table
                    :data="dataList"
                    size="small"
                    v-loading="loading"
                    ref="multipleTable"
                    height="calc(100vh - 300px)"
                    class="table"
                    :row-key="'id'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column prop="auditStatus" label="审核状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("process_state", scope.row.auditStatus, '-') }}
                    </template>
                </el-table-column>
                <el-table-column prop="exhibitionName" label="展览名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="exhibitionType" label="展览类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("exhibition_type", scope.row.exhibitionType, '-') }}
                    </template>
                </el-table-column>
                <el-table-column prop="collectionNames" label="展览藏品" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="beginDate" label="开始时间" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="endDate" label="结束时间" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="createUserName" label="创建者" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createDate" sortable label="创建时间" width="150px">
                    <template slot-scope="scope">
                        {{ scope.row.createDate | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="updateUserName" label="最后更新人"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.updateDate | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="addData(4, scope.row,scope.$index)"
                                   v-show="hasPermissionButton('collection:business:Review:detail')">
                            详情
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="record(scope.row)"
                                   v-show="hasPermissionButton('collection:business:Review:record')">
                            审核记录
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="through(2,3,scope.row)"
                                   v-show="hasPermissionButton('collection:business:Review:adopt')">
                            通过
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="through(2,2,scope.row)"
                                   v-show="hasPermissionButton('collection:business:Review:reject')">
                            驳回
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text_center">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="current"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="size"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>
        <record ref="record"></record>
        <reviewSuggestions ref="suggestions" @updataStatus="getDataList('',1)"></reviewSuggestions>
    </div>
</template>

<script>
import record from "@/views/modules/collection/accounts/module/record.vue";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";

export default {
    name: "rxhibitionReview",
    components: {reviewSuggestions, record},
    props: {
        typePage: {
            type: String,
            default: 'rxhibitionReview',
        },
    },
    data() {
        return {
            inputForm: {
                exhibitionName: '',
                exhibitionType: '',
                collectionName: '',
                exhibitionTime: '',
                beginDate: '',
                endDate: '',
            },

            searchRecord: {},
            dataListSelect: [],
            loading: false,
            dataList: [],

            pageNo2: 0,
            current: 1,
            size: 10,
            total: 0,
        }
    },
    mounted() {
        let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
        if (listSearch) {
            this.inputForm = listSearch.inputForm
            this.pageNo2 = listSearch.current
            this.size = listSearch.size
        }

        this.getDataList('', 1)
    },

    methods: {
        getDataList(type, dividePage) {
            if (type == 1) {
                this.pageNo = 1
            }
            if (dividePage) {
                this.$refs.multipleTable.clearSelection()
            }
            this.loading = true
            if (this.inputForm.exhibitionTime && this.inputForm.exhibitionTime.length == 2) {
                this.inputForm.beginDate = this.inputForm.exhibitionTime[0]
                this.inputForm.endDate = this.inputForm.exhibitionTime[1]
            } else {
                this.inputForm.beginDate = ''
                this.inputForm.endDate = ''
            }
            this.searchRecord = {
                ...this.inputForm,
                auditStatus: 1,
                current: this.pageNo2 ? this.pageNo2 : this.current,
                size: this.size,
            }
            this.$axios(this.api.collection.exhibitioninfoexamineList, this.searchRecord, 'get').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.total = parseInt(res.data.total)
                    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
                    if (listSearch) {
                        this.current = listSearch.current
                        this.pageNo2 = 0
                        sessionStorage.removeItem('listSearch')
                    }
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        //提交
        submitTo(row) {
            this.$confirm(`您是否确认提交数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.collection.exhibitioninfoSubmit + row.exhibitionId, {}, 'put').then(data => {
                    if (data && data.status) {
                        this.$message.success('提交成功')
                        this.getDataList('', 1)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },

        //num 0新增 1详情 2修改、重新提交 4审核详情
        addData(num, row, index) {
            let listSearch = {
                inputForm: this.inputForm,
                current: this.current,
                size: this.size
            }
            sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
            this.searchRecord.current = (this.current - 1) * this.size + index + 1
            this.searchRecord.size = 1
            sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
            this.$router.push({
                path: '/collection/business/exhibitionDetail',
                query: {butType: num, exhibitionId: row.exhibitionId},
            })
        },

        //审核记录
        record(row) {
            this.$refs.record.init(row.exhibitionId, '',11)
        },

        //num 1多个 2单个  type 2驳回 3通过
        through(num, type, row) {
            let setData = []
            let revdId = 11
            if (num == 1) {
                if (this.dataListSelect.length == 0) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                this.dataListSelect.forEach(item => {
                    this.$set(item, 'id', item.exhibitionId)
                })
                setData = this.dataListSelect
            } else {
                this.$set(row, 'id', row.exhibitionId)
                setData = [row]
            }
            this.$refs.suggestions.init(num, setData, type, revdId, this.api.collection.exhibitioninfoExamine)
        },

        resetting() {
            this.$refs.inputForm.resetFields();
            this.inputForm.exhibitionType = ''
            this.getDataList(1, 1)
        },

        exportData() {
            let ids = []
            if (this.dataListSelect.length == 0) {
                this.$message.warning('请至少选择一条数据')
                return
            }

            ids = this.dataListSelect.map(item => {
                if (item) {
                    return item.exhibitionId
                }
            })
            let data = {
                ids: ids,
            }
            this.exportExcel(this.api.collection.exhibitioninfoExport, data, '展览管理列表', 'get')
            this.getDataList('', 1)
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList('', 2);
        },
    },
}
</script>

<style scoped>
.bg-white {
    overflow-y: auto !important;
}
</style>
